<select #ListCombo
            class="form-control"
            [attr.title]="titleText ? titleText : null"
            [attr.disabled]="disabled || readonly ? 'disabled' : null"
            [attr.data-live-search]="(listInternal && listInternal.length > 10) || (listCount && listCount > 10)"
            [(ngModel)]="selectedItem"
            (ngModelChange)="onChange($event)">
                <option *ngIf="emptyOnTop" [value]="''" title="{{emptyText}}"></option>
                <option *ngIf="allOnTop" [value]="''" title="{{allText}}">{{this.l('All')}}</option>
                <ng-template ngFor let-item [ngForOf]="listInternal">
                    <optgroup *ngIf="item.children" label="{{item.label}}">
                        <option *ngFor="let child of item.children" [value]="child.value" [attr.title]="child.title ? child.title : null">{{child.label}}</option>
                    </optgroup>
                    <option *ngIf="!item.children" [value]="item.value" [attr.title]="item.title ? item.title : null">{{item.label}}</option>
                </ng-template>
        </select>