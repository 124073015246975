<div [@routerTransition]>
    <div class="row margin-bottom-5">
        <div class="col-xs-6">
            <div class="page-head">
                <div class="page-title">
                    <h1>
                        <span>{{l("title.notifications")}}</span>
                    </h1>
                </div>
            </div>
        </div>
        <div class="col-xs-6 text-right">
            <button class="btn btn-default" (click)="openNotificationSettingsModal()"><i class="fa fa-cog"></i> {{l("NotificationSettings")}}</button>
            <button class="btn btn-primary blue" (click)="setAllNotificationsAsRead()"><i class="fa fa-check"></i> {{l("SetAllAsRead")}}</button>
        </div>
    </div>
    <div class="portlet light margin-bottom-0">
        <div class="portlet-title portlet-title-filter">
            <div class="inputs inputs-full-width">
                <div class="portlet-input">
                    <form>
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <select #TargetValueFilterSelectionCombobox
                                            class="form-control"
                                            [(ngModel)]="readStateFilter"
                                            name="readStateFilter"
                                            jq-plugin="selectpicker">
                                        <option value="ALL">{{l("All")}}</option>
                                        <option value="UNREAD">{{l("Unread")}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <button class="btn btn-default" style="display: block; width: 100%" (click)="getNotifications()"><i class="fa fa-refresh"></i> {{l("Refresh")}}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="portlet-body">
            <!--<Primeng-Datatable-Start>-->
            <div class="primeng-datatable-container" [busyIf]="primengDatatableHelper.isLoading">
                <p-dataTable #dataTable
                             (onLazyLoad)="getNotifications($event)"
                             [value]="primengDatatableHelper.records"
                             rows="{{primengDatatableHelper.defaultRecordsCountPerPage}}"
                             [paginator]="false"
                             [lazy]="true"
                             emptyMessage="{{l('note.noEntry')}}"
                             [responsive]="primengDatatableHelper.isResponsive">

                    <p-column field="" [sortable]="false" header="{{l('Actions')}}" [style]="{'width':'10%','text-align':'center'}">
                        <ng-template let-record="rowData" pTemplate="body">
                            <div class="btn-group dropdown">
                                <button class="btn btn-xs btn-primary blue" title="{{l('SetAsRead')}}" (click)="setAsRead(record)" [disabled]="isRead(record)">
                                    <i class="fa fa-check" *ngIf="isRead(record)"></i>
                                    <i class="fa fa-circle-o" *ngIf="!isRead(record)"></i>
                                </button>
                            </div>
                        </ng-template>
                    </p-column>
                    <p-column field="notification" header="{{l('Notification')}}">
                        <ng-template let-record="rowData" pTemplate="body">
                            <a *ngIf="record.formattedNotification.url" href="{{record.formattedNotification.url}}" class="{{getRowClass(record)}}">{{truncateString(record.formattedNotification.text, 120)}}</a>
                            <span *ngIf="!record.formattedNotification.url" title="{{record.formattedNotification.text}}" class="{{getRowClass(record)}}">{{truncateString(record.formattedNotification.text, 120)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column field="creationTime" header="{{l('CreationTime')}}" [sortable]="false">
                        <ng-template let-record="rowData" pTemplate="body">
                            <span title="{{record.notification.creationTime != null ? (record.notification.creationTime ) : '' }}" class="{{getRowClass(record)}}">
                                {{fromNow(record.notification.creationTime)}}
                            </span>
                        </ng-template>
                    </p-column>
                </p-dataTable>
                <div class="primeng-paging-container">
                    <p-paginator rows="{{primengDatatableHelper.defaultRecordsCountPerPage}}"
                                 #paginator
                                 (onPageChange)="getNotifications($event)"
                                 [totalRecords]="primengDatatableHelper.totalRecordsCount"
                                 [rowsPerPageOptions]="primengDatatableHelper.predefinedRecordsCountPerPage">
                    </p-paginator>
                    <span class="total-records-count">
                        {{l('label.totalRecordsCount', primengDatatableHelper.totalRecordsCount)}}
                    </span>
                </div>
            </div>
            <!--<Primeng-Datatable-End>-->
        </div>
    </div>
</div>